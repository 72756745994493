.dotted {
  border-style: dashed;
  margin: 2rem 0;
}

hr {
	border-color: #f2f2f2;
	border-width: 1px;
	margin: rem-calc(20) 0;
	max-width: 100%;
	&.tiny {
		border-color: $primary-color;
		border-width: 2px;
	    width: 20px;
	    margin: rem-calc(30) 0;
	}
	&.thick {
		border-color: #f3f3f3;
		border-width: 6px;
	    margin: rem-calc(30) 0;
	}
}

