@import "settings";

@import "node_modules/foundation-sites/assets/foundation";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/pikaday/scss/pikaday.scss";
@import "bower_components/sweetalert/dev/sweetalert.scss";
@import "bower_components/jquery-ui/jquery-ui.min.scss";

//Libraries
@import "libraries/swiper";

// Components
@import "components/mixins";
@import "components/buttons";
@import "components/dividers";
@import "components/type";
@import "components/panels";
@import "components/whitespace";

// Modules
@import "modules/navigation";
@import "modules/footer";

//@import "pages/blog";

// Main Styles
@import "main";