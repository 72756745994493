footer {
	background: #1e2429;
	padding: rem-calc(70) 0 0;
	@include breakpoint(medium down) {
		padding: rem-calc(50) 0 0;
		.menu > li:first-child a { padding-left: 0; }
	}
	@include breakpoint(small down) {
		padding: rem-calc(30) 0 0;
		.menu > li { display: block; a { padding: rem-calc(10 0); } }
	}

	p, li, a { font-weight: 500; color: #6b7c93; }

	.lower-footer {
		border-top: 1px solid #2d353c;
		padding: rem-calc(15) 0 rem-calc(5);
		margin-top: rem-calc(30);
		@include breakpoint(small down) {
			p { text-align: center; }
		}
		@include breakpoint(medium) {
			padding: rem-calc(20) 0 rem-calc(10);
			margin-top: rem-calc(50);
		}
		@include breakpoint(large) {
			padding: rem-calc(30) 0 rem-calc(10);
			margin-top: rem-calc(70);
		}

		p, a { color: #626569; font-size: rem-calc(18); }
	}
}
