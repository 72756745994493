//	------------------------------------------------------------------
//	Adam Wilson: aawilson.co.uk
//
//	Global Styles
// 	------------------------------------------------------------------

	html, body {
		margin: 0;
	}

	//fixes page jumping to top when modal opens
	.is-reveal-open {
	    min-height: 100%;
	    overflow: hidden;
	    position: relative;
	}

	body {
		background: $light-gray;
		margin: 0;
		#loader {
			display: none;
			position: fixed;
			height: 100vh;
			width: 100%;
			z-index: 9999;
			span {
				@include absolute-center;
				content: '';
				display: block;
				padding: rem-calc(12 6);
				border-radius: 4px;
				background: #fff;
				box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
			}

			.fa { color: $primary-color; }
		}
	}

	.page-wrap {
		background: #fff;
		//overflow: hidden;
	}

	a { transition: all .25s ease-in; }

	::selection {
		background: rgba(35, 154, 58, 0.3);
	}
	::-moz-selection {
		background: rgba(35, 154, 58, 0.3);
	}

// 	------------------------------------------------------------------
//	General
// 	------------------------------------------------------------------

	section {
		padding: rem-calc(40) 0;
		@include breakpoint(medium) {
			padding: rem-calc(50) 0;
		}
		@include breakpoint(large) {
			padding: rem-calc(80) 0;
		}
	}

	.hero {
		background-color: $primary-color;
		position: relative;
		background-image: url('../img/home-bg.png');
		background-color: #0c0806;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		min-height: 350px;
		h1 { font-size: rem-calc(32); }
		@include breakpoint(medium) {
			min-height: 450px;
			h1 { font-size: rem-calc(42); }
		}
		@include breakpoint(large) {
			min-height: 600px;
		}

		&.standard {
			@include background-gradient(#249a38, #13ad6a, 'horizontal');
			position: relative;
			min-height: auto;
			@include breakpoint(medium) {
				min-height: 375px;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('../img/hero-bg.png');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center right;
				opacity: .75;
				z-index: 1;
			}
		}
		.caption {
			position: relative;
			width: 100%;
			z-index: 2;
			padding: rem-calc(10 0);
			h1, h2, h3, p { color: #fff; }
			.btn { margin-top: rem-calc(10); }
			@include breakpoint(medium) {
				@include vertical-center;
				h1 { margin-top: rem-calc(60); }
				.btn { margin-top: rem-calc(20); }
				padding-top: rem-calc(30 0 0 0);
			}
		}
	}

	.sea {
		background: $light-gray;
		position: relative;
		padding: rem-calc(30 0);
		@include breakpoint(medium) {
			height: 100vh;
			.island {
				@include horizontal-center;
				width: 100%;
			}
		}
	}

	.contact-bar {
		padding: rem-calc(30) 0;
		@include breakpoint(medium) {
			padding: rem-calc(45) 0;
		}
		@include breakpoint(large) {
			padding: rem-calc(60) 0;
		}

		h1 { color: #fff; }
		h3 { color: #fff; font-weight: 400; }
	}

	.callout {
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		padding: rem-calc(20 20);
		@include breakpoint(medium) {
			padding: rem-calc(30 60);
		}

		p, li { font-size: rem-calc(16); }
		.alert p, .alert li { font-size: rem-calc(16); text-align: left; }
	}

	.testimonials {
		margin-top: rem-calc(40);
		margin-bottom: rem-calc(30);
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		position: relative;
		overflow: visible;
		.slide-nav {
			@include vertical-center;
			width: 100%;
			z-index: 9999;
			height: 54px;
			.next {
				position: absolute;
				right: -25px;
				@include breakpoint(small down) {
					right: -15px;
					img { width: 40px; }
				}
			}
			.prev {
				position: absolute;
				left: -25px;
				transform: rotate(180deg);
				@include breakpoint(small down) {
					left: -15px;
					img { width: 40px; }
				}
			}
		}

		.swiper-card {
			.slide-image {
				height: 200px;
				width: 100%;
				@include breakpoint(medium) {
					width: 33.33333333%;
					height: 100%;
					position: absolute;
				}
				background-color: #f7f7f7;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
			.slide-details {
				padding: rem-calc(35);
				@include breakpoint(medium) {
					padding: rem-calc(30 45);
				}
				@include breakpoint(large) {
					padding: rem-calc(70);
				}
			}
		}
	}

	.screen-wrap {
		position: relative;
		overflow: hidden;
		.screen {
			margin-top: rem-calc(15);
			@include breakpoint(medium) {
				position: absolute;
				max-width: 900px;
				right: -500px;
				margin-top: 0;
			}
			@include breakpoint(large) {
				max-width: 650px;
				right: -150px;
			}
			@include breakpoint(xlarge) {
				max-width: 800px;
				right: -150px;
			}
		}
	}

	.accordion {
		.accordion-title:hover, .accordion-title:focus {
			background: darken($secondary-color, 10%);
			color: #fff;
		}
		.accordion-content, .accordion-content p {
			font-size: rem-calc(18);
		}
	}

	.reveal {
		outline: none;
		border: none;
		border-radius: 4px;
		.fa { color: $primary-color; }
	}

	.legend {
		.title { font-weight: 700; font-size: rem-calc(17); margin-bottom: rem-calc(5); }
		li { font-size: rem-calc(14); padding-right: rem-calc(10); }
		.payment {
		    display: inline-block;
		    width: 12px;
		    height: 12px;
		}
	}

// 	------------------------------------------------------------------
//	Dashboard
// 	------------------------------------------------------------------

	aside {
		@include background-gradient(#249a38, #13ad6a, 'horizontal');
		@include breakpoint(large) {
			height: 100vh;
			width: 16.66666667%;
			position: fixed;
		}

		.logo {
			border-bottom: 1px solid #128b4c;
			box-shadow: 0 4px 6px rgba(50,50,93,.08), 0 1px 3px rgba(0,0,0,.05);
			@include background-gradient(#249a38, #13ad6a, 'horizontal');
			padding: rem-calc(18 20);
			@include breakpoint(large) {
				padding: rem-calc(24 20);
			}
			@include breakpoint(xlarge) {
				padding: rem-calc(24 30);
			}
			img { transition: .25s all ease-in; }
			a:hover img { opacity: .8; }
		}

		.menu {
			margin: rem-calc(13);
			@include breakpoint(xlarge) {
				margin: rem-calc(20 30);
			}
			li {
				display: table;
				a {
					color: #fff;
					padding: rem-calc(15 0);
					display: table-cell;
  					vertical-align: middle;
  					&:hover {
  						opacity: 0.65;
  					}
					img { vertical-align: middle; }
				}
			}
		}

		.help-box {
			position: absolute;
			background: #fff;
			border-radius: 4px;
			color: $black;
			box-shadow: 0 4px 6px rgba(50,50,93,.08), 0 1px 3px rgba(0,0,0,.05);
			transition: .25s all ease-in;
			bottom: 30px;
			padding: rem-calc(8 25 8 15);
			left: 30px;
			display: none;
			@include breakpoint(large) {
				padding: rem-calc(8 10 8 10);
				left: 20px;
				display: block;
				img { margin-right: rem-calc(4); }
			}
			@include breakpoint(xlarge) {
				padding: rem-calc(8 25 8 15);
				left: 30px;
				img { margin-right: rem-calc(8); }
			}
			&:hover { background: rgba(255,255,255,0.85); }
		}
	}

	.dashboard {
		background: $light-gray;
		.dashboard-header {
			min-height: 89px;
			background: #fff;
			box-shadow: 0 4px 6px rgba(50,50,93,.08), 0 1px 3px rgba(0,0,0,.05);
			padding: rem-calc(10 0);
			@include breakpoint(xlarge) {
				padding: rem-calc(13 10 10 10);
				.top-bar {
					li { padding: 0; vertical-align: top; }
				}
			}
			.name {
				font-size: rem-calc(18);
				@include breakpoint(medium down) {
					padding: rem-calc(5 0);
				}
			}

			.tokens-btn {
				line-height: 1.5;
				padding: .65em 1.25em;
				span {
					margin-left: rem-calc(10);
					padding-left: rem-calc(10);
					border-left: 1px solid #128b4c;
				}
			}
		}
	}

	.content-area {
		background: #fff;
		border-radius: 4px;
		margin: rem-calc(18 7);
		padding: rem-calc(18 0);
		@include breakpoint(medium) {
			padding: rem-calc(18);
			margin: rem-calc(24);
		}
		@include breakpoint(large) {
			padding: rem-calc(30);
			margin: rem-calc(30);
		}
	}

	.searches {
		border: 1px solid #e5e5e5;
		padding: 0;
		border-collapse: collapse;
		margin-bottom: 0;
		thead, tbody {
			margin: 0;
			border: none;
			padding: 0;
			td, th {
				border-right: 1px solid #e5e5e5;
				vertical-align: top;
			}
			.actions {
				text-align: center;
				vertical-align: middle;
				.btn { min-width: 150px; }
			}
			.name { vertical-align: middle; }
		}
	}

	.table-scroll {
		position: relative;
		margin-bottom: rem-calc(30);
		font-size: rem-calc(15);
		.columns {
			padding-left: rem-calc(10);
			padding-right: rem-calc(10);
		}
		.payment {
		    display: inline-block;
		    width: 12px;
		    height: 12px;
		}
	}

	.sample-table {
		position: relative;
		padding: 0;
		table {
			border: none;
			margin: 0;
		}
	}

	.locked-cover {
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		bottom: 0;
		padding: 0;

		&:before {
			content: '';
			@include absolute-center;
			width: 99%;
			height: 92%;
			display: block;
			-webkit-filter: url('#blur');
			filter: url('#blur');
			-webkit-filter: blur(7px);
			filter: blur(7px);
			background-size: cover;
			background: $primary-color;
			opacity: .9;
		}

		.button-group {
			text-align: center;
			margin-bottom: 0;
			@include absolute-center;
			h3 {
				color: #fff;
			}
		}
	}

	.table-list {
		list-style: none;
		margin: -10px;
		li {
			padding: rem-calc(10);
			font-size: rem-calc(16);
			line-height: rem-calc(16);
			border-bottom: 1px solid #e5e5e5;
			&.payments {
				padding: rem-calc(11 10 9);
			}
		}
		li:last-child { border-bottom: none; }
	}

	@include breakpoint(medium down) {
		.table-scroll table td { min-width: 110px; }
	}

	@include breakpoint(medium) { .table-scroll table { width: 100%; } }

	.customer-actions {
		li {
			padding: rem-calc(0 6);
			i { color: $primary-color; }
			a { padding: 0; }
		}
	}

	#container {
	  margin: rem-calc(10) auto;
	  width: 100px;
	  height: 100px;
	}

	.price-card {
		text-align: center;
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		.header {
			background: #f1f1f1;
			padding: rem-calc(40 5);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			p { margin: 0;}
			&.featured {
				@include background-gradient(#249a38, #13ad6a, 'horizontal');
				h2, p { color: #fff; }
			}
		}
		.caption {
			padding: rem-calc(40 5 30 5);
		}
	}

	.profile-card {
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		margin-bottom: rem-calc(45);
		@include breakpoint(medium) { margin-bottom: 0; }

		.profile-header img {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
		.avatar-actions {
			padding: rem-calc(20 15);
			background: $light-gray;
		}
		.profile-body {
			padding: rem-calc(20 15 30 15);
			p { margin-bottom: rem-calc(5); display: inline-block; font-size: rem-calc(17); }
			label { font-size: rem-calc(16); font-weight: 700; }
		}
	}

	.dashboard-box {
		text-align: center;
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		margin-bottom: rem-calc(35);
		@include breakpoint(medium) { margin-bottom: 0; }

		.header {
			background: #f7f7f7;
			padding: rem-calc(20 15);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			h4 { font-weight: 600; margin: 0; font-size: rem-calc(20); }
		}
		.content {
			padding: rem-calc(20 10);
			@include breakpoint(xlarge) { padding: rem-calc(20 15); }
			table { margin: 0; }
			p { font-size: rem-calc(16); }
		}
		.action {
			padding: rem-calc(17 15);
			border-top: 2px solid #f7f7f7;
		}
	}

	.avatar {
		display: inline-block;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 40px;
		height: 40px;
		border: 2px solid #ccc;
		border-radius: 100%;
		@include breakpoint(medium) { margin: rem-calc(5 10 0 0); }
		@include breakpoint(xlarge) { margin: 0; }
	}

	.autocomplete {
		position: relative;

		[type='text'], [type='password'], [type='email'], [type='tel'], select {
			height: 45px;
			border-color: #e0e0e0;
			border-radius: 4px;
			margin-top: rem-calc(5);
		}

		.results {
			position: absolute;
			top: 43px;
			left: 0;
			width: 100%;
			height: 130px;
    		overflow-y: auto;
			background: #fff;
			border: 1px solid #e0e0e0;
    		border-bottom-left-radius: 4px;
    		border-bottom-right-radius: 4px;
			box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
			ul {
				list-style: none;
				margin: 0;
				li {
					font-size: rem-calc(16);
					padding: rem-calc(5 15);
					border-bottom: 1px solid #e0e0e0;
					&:hover {
						background: #f3f3f3;
						cursor: pointer;
					}
				}
			}
		}
	}

	.actions {
		list-style: none;
		li, li a {
			color: $black;
			font-weight: 400;
			font-size: rem-calc(16);
		}
	}

	.payment {
		display: inline-block;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		background: #00ff00;
		margin: 0 3px;
		&.payment-1 { background: #00ff00; }
		&.payment-2 { background: #ff0000; }
		&.payment-3 { background: #38761d; }
		&.payment-4 { background: #ffb92a; }
		&.payment-5 { background: #0000ff; }
		&.payment-null-1 { background: #eee; }
		&.payment-null-2 { background: #ccc; }
	}

	.payments-sticky {
		width: 100%;
		background: #fff;
		p { margin: 0; }
		&.is-stuck {
			padding: rem-calc(15 0);
			box-shadow: 0 6px 24px -8px rgba(255,255,255,1);
		}
	}

	.circle {
	    margin: rem-calc(10) auto;
	    display: inline-block;
	    position: relative;
	    text-align: center;
	    line-height: 1.2;
		strong {
		    @include absolute-center;
		    width: 100%;
		    text-align: center;
		    font-size: 30px;
		}
	}

	.admin-bar {
		display: block;
		width: 100%;
		padding: rem-calc(7 30);
		background: $secondary-color;
		color: #fff;
		a, p { color: #fff; font-size: rem-calc(16); }
		a { text-decoration: underline; }
	}

// 	------------------------------------------------------------------
//	Form
// 	------------------------------------------------------------------

	form {
		[type='text'], [type='password'], [type='email'], [type='tel'], select {
			height: 45px;
			border-color: #e0e0e0;
			border-radius: 4px;
			margin-top: rem-calc(5);
		}

		select { margin: 0; }

		textarea {
			border-color: #e0e0e0;
			border-radius: 4px;
			margin-top: rem-calc(5);
			line-height: 1.6;
		}
	}

	.form-wrap {
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		padding: rem-calc(20 20);
		@include breakpoint(medium) {
			padding: rem-calc(30 25);
		}

		p, li { font-size: rem-calc(18); }

		.errors {
			p, li { font-size: rem-calc(16); color: #e63030; }
		}
	}


	.search-wrap {
		max-width: 350px;
		display: inline-block;
		@include breakpoint(medium only) {
			float: right;
			margin-bottom: rem-calc(20);
		}
	}

	.search-form {
		@include breakpoint(xlarge) {
			width: 100%;
		}
		[type='text'] {
			max-width: inherit;
			margin: 0;
			border-color: #e5e5e5;
			box-shadow: none;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		.query {
			border-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		.postcode {
			border-right: 0;
			border-left: 1px solid #e5e5e5;
			border-radius: 0;
		}

		.input-group {
		    margin-bottom: 0;
		}

		.search-btn {
			background: #f0f0f0;
			border: 1px solid #e5e5e5;
			border-left: 0;
			height: 45px;
			width: 45px;
			padding: rem-calc(5);
		    border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.has-error .help-block {
		color: #e63030;
		margin-top: -10px;
	    margin-bottom: 5px;
	    display: block;
	}

	.alert {
		display: block;
		width: 100%;
		padding: rem-calc(10 13);
		margin-bottom: rem-calc(15);
		color: #fff;
		border-radius: 4px;
		&.alert-info {
			background: $secondary-color;
		}
		&.alert-error {
			background: #e63030;
		}
		&.alert-success {
			background: $primary-color;
		}
	}
