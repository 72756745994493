.btn {
	cursor: pointer;
	text-decoration: none;
	transition: all .25s ease-in-out;
	display: inline-block;
	border-radius: 4px;
	font-weight: 400;
	line-height: 1.5;
	font-size: rem-calc(16);
	padding: .65em 1.25em;
	@include breakpoint(medium) {
		padding: .65em 2em;
	}
	&.lg { padding: 1em 2.75em; }
	&.sm { padding: .65em 1.5em; font-size: rem-calc(14); }
	&.block {
		display: block;
		width: 100%;
	}

	&.dropdown::after {
	    display: block;
	    width: 0;
	    height: 0;
	    border: inset 0.4em;
	    content: '';
	    border-bottom-width: 0;
	    border-top-style: solid;
	    border-color: #fefefe transparent transparent;
	    position: relative;
	    top: 10px;
	    display: inline-block;
	    float: right;
	    margin-left: .5em;
	}
}

.primary {
	@include background-gradient(#249a38, #13ad6a, 'horizontal');
	color: #fff;
	&:hover, &:active, &:focus, &:active:focus, &:active:hover, {
		@include background-gradient(#13ad6a, #249a38, 'horizontal');
		color: #fff;
	}
}

.secondary {
	background: $secondary-color;
	color: #fff;
	&:hover, &:active, &:focus, &:active:focus, &:active:hover, {
		background: lighten($secondary-color, 10%);
		color: #fff;
	}
}

.white {
	background: #fff;
	color: $primary-color;
	&:hover, &:active, &:focus, &:active:focus, &:active:hover, {
		background: rgba(255,255,255,0.85);
		color: $primary-color;
	}
}

.ghost {
	border: 2px solid #fff;
	background: transparent;
	color: #fff;
	&:hover, &:active, &:focus, &:active:focus, &:active:hover, {
		background: #fff;
		color: $primary-color;
	}
}