h1 {
    color: $secondary-color;
	font-weight: 700;
    line-height: 1.3;
}

h2 {
    font-weight: 700;
    color: $secondary-color;
    margin-bottom: .5rem;
}

h3 {
    font-weight: 700;
    color: $secondary-color;
    margin-bottom: .75rem;
}

h4 {
    font-weight: 400;
}

h5 {
    font-weight: 300;
    color: $primary-color;
    text-transform: uppercase;
    font-family: $body-font-family;
}

h6 {
    font-weight: 400;
    font-family: $body-font-family;
}

p, li {
    font-size: rem-calc(17);
    font-weight: 400;
    @include breakpoint(medium) {
        font-size: rem-calc(20);
    }
}

.dark {
    h1, h2, h3, h4, h5, p, li {
        color: #fff;
    }
}

.grey-link {
    color: $black;
}
