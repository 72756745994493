header {
    max-width: 1200px;
    position: relative;
    z-index: 5;
    margin: 0 auto;
    padding: rem-calc(15 0);
    @include breakpoint(large) {
        padding: rem-calc(20 0);
    }

    nav {
        padding: 0 !important;
        .menu > li > a {
            color: #fff;
            padding: 0.7rem .8rem;
            &.active, &:hover {
                opacity: .5;
            }
        }
        img {
            transition: .25s all ease-in;
            &:hover { opacity: .75; }
        }
        .ghost { font-size: rem-calc(18); &:hover { opacity: 1 !important; } }

        .search-form {
            margin-top: rem-calc(15);
            @include breakpoint(medium) { margin-left: rem-calc(35); margin-top: 0; }
            input[type='text'], input[type='password'], input[type='email'], input[type='tel'], select {
                height: 40px;
                font-size: rem-calc(15);
            }

            .search-btn {
                width: 40px;
                height: 40px;
            }
        }
    }
}

@include breakpoint(medium only) {
    .top-bar .top-bar-right {
        width: 100%;
        float: left;
        .menu > li:first-child > a { padding-left: 0; }
    }
}

.is-dropdown-submenu, .dropdown-pane {
    border: none;
    background-color: #fff !important;
    box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
    li > a {
        color: $black;
        font-size: rem-calc(18);
        padding: 0.5rem 1rem;
        &:hover { color: $primary-color; }
    }
}

.off-canvas {
    background: $secondary-color;
    .menu > li {
        margin-top: rem-calc(25);
        padding: rem-calc(5 20);
        display: block;
        a {
            color: #fff;
            padding: 0;
            font-size: rem-calc(20);
            &.ghost {
                display: inline-block;
                padding: rem-calc(10 20);
            }
        }
    }
}

.toggle {
    display: block;
    width: 36px;
    height: 23px;
    position: relative;
    z-index: 99999;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin: rem-calc(10) auto;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        border-radius: 6px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
}

.toggle span:nth-child(1) { top: 0px; }
.toggle span:nth-child(2), .toggle span:nth-child(3) { top: 10px; }
.toggle span:nth-child(4) { top: 20px; }

.toggle.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
}

.toggle.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.toggle.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.toggle.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}
