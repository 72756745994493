.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }

.m1  { margin: .5rem }
.mt1 { margin-top: .5rem }
.mr1 { margin-right: .5rem }
.mb1 { margin-bottom: .5rem }
.ml1 { margin-left: .5rem }

.m2  { margin: 1rem }
.mt2 { margin-top: 1rem }
.mr2 { margin-right: 1rem }
.mb2 { margin-bottom: 1rem }
.ml2 { margin-left: 1rem }

.m3  { margin: 2rem }
.mt3 { margin-top: 2rem }
.mr3 { margin-right: 2rem }
.mb3 { margin-bottom: 2rem }
.ml3 { margin-left: 2rem }

.m4  { margin: 4rem }
.mt4 { margin-top: 4rem }
.mr4 { margin-right: 4rem }
.mb4 { margin-bottom: 4rem }
.ml4 { margin-left: 4rem }

.mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
.mxn2 { margin-left: -1rem; margin-right: -1rem; }
.mxn3 { margin-left: -2rem; margin-right: -2rem; }
.mxn4 { margin-left: -4rem; margin-right: -4rem; }

.mx-auto { margin-left: auto; margin-right: auto; }
.p0 { padding: 0 }
.pb0 { padding-bottom: 0; }
.pt0 { padding-top: 0; }

.p1  { padding: .5rem }
.py1 { padding-top: .5rem; padding-bottom: .5rem }
.px1 { padding-left: .5rem; padding-right: .5rem }

.p2  { padding: 1rem }
.py2 { padding-top: 1rem; padding-bottom: 1rem }
.px2 { padding-left: 1rem; padding-right: 1rem }

.p3  { padding: 2rem }
.py3 { padding-top: 2rem; padding-bottom: 2rem }
.px3 { padding-left: 2rem; padding-right: 2rem }

.p4  { padding: 4rem }
.py4 { padding-top: 4rem; padding-bottom: 4rem }
.px4 { padding-left: 4rem; padding-right: 4rem }